import React from "react"
import Impressum from "../modules/impressum"
import Layout from "../modules/layout"
import Seo from "../modules/seo"

const impressum = ({ location }: any) => {
  return (
    <Layout location={location}>
      <Seo title="Impressum">
        <Impressum />
      </Seo>
    </Layout>
  )
}

export default impressum
