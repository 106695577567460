import React from "react"

//styles
import { Main, Container } from "./Impressum.styles"

const Impressum = () => (
  <Main>
    <Container>
      <h1>Impressum</h1>
      <section>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          Ammerthaler Institut
          <br />
          Am Spitzberg 9a
          <br />
          92260 Ammerthal
        </p>
        <br />
        <p>
          <b>Vertreten durch:</b>
        </p>
        <p>Christine Lehner</p>
        <p>Telefonnummer: 09628/914880</p>
        <p>
          Email:{" "}
          <a href="mailto: chlehner@ammerthaler-institut.de">
            chlehner@ammerthaler-institut.de
          </a>
        </p>
        <br />
        <p>Sabine Weihe</p>
        <p>Telefonnummer: 089/43529376</p>
        <p>
          Email:{" "}
          <a href="mailto: sweihe@ammerthaler-institut.de">
            sweihe@ammerthaler-institut.de
          </a>
        </p>
        <br />
      </section>

      <section>
        <h2>Kontakt</h2>
        <p>Telefon: 09628/914880</p>
        <p>Telefax: 09628/914881</p>
        <p>
          Email:{" "}
          <a href="mailto: info@ammerthaler-institut.de">
            info@ammerthaler-institut.de
          </a>
        </p>
        <p>
          Webseite:{" "}
          <a href="https://www.ammerthaler-institut.de" target="__blank">
            www.ammerthaler-institut.de
          </a>
        </p>
      </section>

      <section>
        <h2>Redaktionell verantwortlich</h2>
        <p>Christine Lehner und Sabine Weihe</p>
        <p>Am Spitzberg 9a</p>
        <p>92260 Ammerthal</p>
      </section>
      <section>
        <h2>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </section>
      <section>
        <h2>Haftung für Inhalte</h2>
        <p>
          Wir weisen ausdrücklich darauf hin, dass wir für Links auf externe
          Seiten keine Haftung übernehmen. Auf dieser Webseite ausgewiesene
          Markennamen und Warenzeichen gehören ihren jeweiligen Eigentümern.
        </p>
        <p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
      </section>
      <section>
        <h2>Webdesign-Konzept und Realisierung:</h2>
        <p>
          <a
            href="https://www.xn--berkayyurdagl-7ob.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Berkay Yurdagül
          </a>
          &nbsp;und&nbsp;
          <a
            href="https://dribbble.com/lenalena"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lea Neumeier
          </a>
        </p>
      </section>
    </Container>
  </Main>
)

export default Impressum
